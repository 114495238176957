














import { Component, Vue } from 'vue-property-decorator';
import RegisterCard from '@/components/cards/RegisterCard.vue';

@Component({
  components: { RegisterCard },
})
export default class RegisterNbbu extends Vue {
}
